import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Control, FieldValues, Path } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

type FormInputProps<T extends FieldValues> =
  React.ComponentPropsWithRef<"input"> & {
    control: Control<T>;
    name: Path<T>;
    label: string;
    isPending?: boolean;
    type?: string;
    options?: { value: string; label: string }[];
  };

export const FormInput = <T extends FieldValues>(props: FormInputProps<T>) => {
  const { control, name, label, isPending, disabled, type, options, ...rest } =
    props;
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          {type !== "hidden" && <FormLabel>{label}</FormLabel>}
          <FormControl>
            {type === "select" && options ? (
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger
                  className={cn(fieldState.error && "border-red-500")}
                >
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  {options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : type === "textarea" ? (
              <Textarea
                {...field}
                value={field.value as string}
                onChange={(e) => field.onChange(e.target.value)}
                className={cn(fieldState.error && "border-red-500")}
                disabled={isPending || disabled}
              />
            ) : (
              <Input
                {...field}
                {...rest}
                type={type}
                className={cn(fieldState.error && "border-red-500")}
                disabled={isPending || disabled}
              />
            )}
          </FormControl>
          {type !== "hidden" && <FormMessage className="text-xs" />}
        </FormItem>
      )}
    />
  );
};
