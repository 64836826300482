import { z } from 'zod';

const EMAIL_SCHEMA = z
  .string()
  .min(1, 'Email Address is required.')
  .email('Invalid Email Address.');

export const loginSchema = z.object({
  email: EMAIL_SCHEMA,
  password: z.string().min(1, 'Password is required.'),
});

export const registerSchema = z.object({
  email: EMAIL_SCHEMA,
  name: z
    .string()
    .min(1, {
      message: 'Name is required.',
    })
    .min(4, 'Name must be at least 4 characters.')
    .max(24, 'Maximum length of Name is 24 characters.'),
  password: z
    .string()
    .min(1, 'Password is required.')
    .min(6, 'Password must be at least 6 characters.'),
  acceptTerms: z.boolean().refine((val) => val === true, {
    message: 'You must accept the terms and conditions',
  }),
});

export const resendSchema = z.object({
  email: EMAIL_SCHEMA,
});

export const resetPasswordSchema = z.object({
  email: EMAIL_SCHEMA,
});

export const newPasswordSchema = z
  .object({
    password: z
      .string()
      .min(1, 'Password is required.')
      .min(6, 'Password must be at least 6 characters.'),
    confirmPassword: z.string().min(1, 'Confirm Password is required.'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Password doesn't match.",
    path: ['confirmPassword'],
  });

export const twoFactorSchema = z.object({
  code: z
    .string()
    .regex(/^[0-9]+$/, 'Code must be a number.')
    .length(6, 'Code must be 6 digits long.'),
});

export const profileSchema = z.object({
  name: z.optional(
    z
      .string()
      .min(1, {
        message: 'Name is required.',
      })
      .min(4, 'Name must be at least 4 characters.')
      .max(24, 'Maximum length of Name is 24 characters.')
  ),
  email: z.optional(z.string().email()),
  password: z.optional(
    z.string().min(6, 'Password must be at least 6 characters.')
  ),
  newPassword: z.optional(
    z.string().min(6, 'New Password must be at least 6 characters.')
  ),
  isTwoFactorEnabled: z.optional(z.boolean()),
  companyDescription: z.string().optional(),
  aiExamples: z.array(z.string()).optional(),
  newExample: z.string().optional(),
  smtpHost: z.optional(
    z.string().min(1, {
      message: 'Name is required.',
    })
  ),
  smtpPort: z
    .union([z.number(), z.string()])
    .refine((val) => val === '' || (Number(val) >= 1 && Number(val) <= 65535), {
      message: 'Port must be between 1 and 65535',
    })
    .transform((val) => (val === '' ? null : Number(val)))
    .nullable(),
  smtpUsername: z.optional(z.string()),
  smtpPassword: z.optional(z.string()),
});

export const contactListSchema = z.object({
  name: z.string().min(1, 'Name is required.'),
  userId: z.string().min(1, 'User ID is required.'),
});

export const textBlockSchema = z.object({
  name: z.string().min(1),
  text: z.string().min(1),
  type: z.enum(['signature', 'text']),
});

export const SubmitMailFormSchema = z.object({
  selectedCampaignId: z
    .string({
      required_error: 'Please select an email campaign.',
    })
    .min(1, 'Please select an email campaign.')
    .refine((val) => val.trim() !== '', {
      message: 'Please select an email campaign.',
    }),
  selectedContactListId: z
    .string({
      required_error: 'Please select a contact list.',
    })
    .min(1, 'Please select a contact list.')
    .refine((val) => val.trim() !== '', {
      message: 'Please select a contact list.',
    }),
  showContactedContacts: z.boolean(),
});
