export const publicRoutes: string[] = [
  '/',
  '/verify',
  '/imprint',
  '/impressum',
  '/datenschutz',
  '/terms',
  '/api/addArticle',
];

export const authRoutes: string[] = [
  '/login',
  '/register',
  '/error',
  '/resend',
  '/reset',
  '/new-password',
  '/two-factor',
];

export const dashboardRoutes: Record<string, string> = {
  dashboard: '/app/dashboard',
  articles: '/app/articles',
};

export const apiAuthPrefix: string = '/api/auth';

export const DEFAULT_LOGIN_REDIRECT: string = '/app/articles';
